import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import {
  loadWidgetSettings,
  setWidgetInstalled,
  sendAnalyticsEvent,
  getWidgetType,
  getAPIkey
} from "../WidgetAPICalls";
import {
  getNextPossibleDateAndTime,
  getAllowedCountries,
  triggerPublicEvent
} from "../HelperMethods";
import WidgetCallForm from "./WidgetCallForm";
import DigitalCallForm from "./DigitalCallForm";
import SmartTemplate from "./SmartTemplate";
import ModernTemplate from "./ModernTemplate";
import MessageForm from "./MessageForm";
import LimeCallClientClass from "./LimecallClient";
import './telinput/main.css';
import './custom/main.scss';
import moment from 'moment';


class Widget extends Component {
  floatingStyle = {
    bottom_left: {
      display: "flex",
      bottom: "37px",
      left: "37px"
    },
    bottom_right: {
      display: "flex",
      bottom: "37px",
      right: "37px"
    },
    top_right: {
      display: "flex",
      top: "37px",
      right: "37px"
    },
    top_left: {
      display: "flex",
      top: "37px",
      left: "37px"
    }
  }
  fontStyle = {}

  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      modalIsOpen: false,
      disableCalls: false,
      activeTab: 'callnow',
      scheduleTime: null,
      isSameDay: null,
      scheduleDate: null,
      widgetVisibility: 0,
      widgetSettings: {}
    };
  }

  installWidget = () => {
    let host_name = window.location.origin;
    if (!host_name.includes('limecall.com') && !host_name.includes('localhost')) {
      // console.log(host_name)
      setWidgetInstalled();
    }
  }

  fetchUTMparams = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let utm_data = {};
    if (urlParams.has('utm_source')) {
      utm_data.utm_source = urlParams.get('utm_source');
    }
    if (urlParams.has('utm_medium')) {
      utm_data.utm_medium = urlParams.get('utm_medium');
    }
    if (urlParams.has('utm_campaign')) {
      utm_data.utm_campaign = urlParams.get('utm_campaign');
    }
    if (urlParams.has('utm_term')) {
      utm_data.utm_term = urlParams.get('utm_term');
    }
    if (urlParams.has('utm_content')) {
      utm_data.utm_content = urlParams.get('utm_content');
    }

    let utm_data_length = Object.keys(utm_data).length;
    if (utm_data_length === 0) {
      utm_data = null;
    }

    return utm_data;
  }

  loadWidgetSettings = () => {
    // var widgetSettings =null;
    loadWidgetSettings().then((response) => {
      // console.log(response);
      let org_response = response;
      let widget_type = getWidgetType();
      let pageSessionStartTime = moment().format("YYYY-MM-DD HH:mm:ss");
      response.pageSessionStartTime = pageSessionStartTime;
      if (widget_type === "personal") {
        response.widget_settings.call_me_later = 0;
        response.widget_settings.classic_template_design_type = 1;
        response.widget_settings.team_required_before_call = 0;
        response.widget_settings.team_enabled_before_call = 0;
        response.widget_settings.team_in_call_now_tab = 0;
        response.widget_settings.team_in_call_later_tab = 0;
        response.widget_settings.team_in_digital_calls_tab = 0;
        response.widget_settings.team_in_leave_message = 0;
        response.widget_settings.pId = response.pId;
        response = response.widget_settings;
      }
      let allowWidget = false;
      let hostName = window.location.hostname;
      this.customVisibilityAttacher(response.triggers.visibility_triggers_status, response.triggers.custom_visibility_triggers);

      if (response.whitelisted_countries.length > 0 && response.whitelisted_countries.indexOf(response.default_country_code) === -1) {
        response.default_country_code = response.whitelisted_countries[0];
      }
      // if(response.whitelisted_countries.indexOf("US")==-1){
      //   response.whitelisted_countries.push('US');
      // }
      if (response.is_appsumo === 1) {
        response.whitelisted_countries = ["US", "CA"];
      }
      // console.log(response.whitelisted_countries);
      let source = window.location.href;
      response.source = source;

      if (response.installed === 0) {
        this.installWidget();
      }

      let utm_data = this.fetchUTMparams();
      response.utm_data = utm_data;

      if (response.allow_to_call === 1) {
        triggerPublicEvent('widgetCallAllowed', null)
      } else {
        response.template_type = 1;
        response.classic_template_design_type = 1;
        response.leave_message = 1;
        response.widget_type = 0;
        if (response.available_time_widget.length > 0) {
          response.offline_schedule_call_message = "Sorry, we're away right now. Please schedule a call for suitable time."
        } else {
          response.leave_message_tab_text = "Sorry, we're away right now. Please leave us a message."
        }
      }

      let allowedDomain = response.available_domains;
      // response.active=1;
      if (response.active === 0) {
        allowWidget = false;
        console.log("Widget is not activated")
        return false;
      } else if (response.active === 1) {
        allowWidget = true;
        triggerPublicEvent('widgetLoaded', null)
      }

      if (allowWidget) {
        allowWidget = false;
        let host_name = window.location.origin;
	if (!host_name.includes('limecall.com') && !host_name.includes('localhost')) {
          allowedDomain.forEach(function (domain) {
            if (hostName === domain) {
              allowWidget = true;
            } else {
              let tmp = hostName.split(".");
              let tmpb = domain.split(".");
              if ((tmp[tmp.length - 1] === tmpb[tmpb.length - 1]) && (tmp[tmp.length - 2] === tmpb[tmpb.length - 2])) {
                allowWidget = true
              }
            }
          });
        } else {
          allowWidget = true;
          // console.log("locally loaded");
        }
        if (allowedDomain.length < 2) {
          // console.log("domain  security is off")
          allowWidget = true;
        }

        // console.log("total domains allowed  " + allowedDomain.length)

      }
      // allowWidget = true;
      if (!allowWidget) {
        console.log("Domain not allowed for widget");
        return false;
      }
      const { scheduleTime, scheduleDate, isSameDay } = getNextPossibleDateAndTime(response.available_time);
      const allowedCountries = getAllowedCountries(response.whitelisted_countries);
      response.allowedCountries = allowedCountries;
      const disableCalls = parseInt(response.allow_to_call, 10) === 0;
      let modalIsOpen = false;

      if (widget_type === "shareable") {
        widget_type = 1;
        response.template_type = 1;
        modalIsOpen = true;
      } else if (widget_type === "personal") {
        response.template_type = 1;
        widget_type = 2;
      } else if (widget_type === "intercom_personal") {
        widget_type = 3;
      } else if (widget_type === "intercom_platform") {
        widget_type = 4;
      } else {
        widget_type = 0;
      }
      if (response.call_me_later === 0 && response.call_now === 0 && response.leave_message === 0 && response.digital_call === 0) {
        response.call_me_later = 1;
        response.offline_schedule_call_message = response.language_data['call-later-heading'];

      }
      if (widget_type === 4 || widget_type === 3) {
        response.template_type = 3;
      }
      response.widget_type = widget_type;
      if (response.bubble_template === 2) {
        response.template_type = 1;
        response.classic_template_design_type = 1;
      }

      let activeTab = isSameDay ? "callnow" : "calllater";
      if (response.digital_call === 1) {
        activeTab = "clicktocall"
      } else if (response.call_now === 1 && isSameDay) {
        activeTab = "callnow"
      }
      else if (response.call_now !== 1 && response.call_me_later === 1 && isSameDay) {
        activeTab = "calllater"
      }
      else if (response.call_me_later === 1 && !isSameDay) {
        activeTab = "calllater"
      } else {
        activeTab = "leavemessage";
      }
      activeTab = disableCalls ? (response.call_me_later ? "calllater" : "leavemessage") : activeTab;

      this.fontStyle = {
        fontFamily: response.widget_font
      }
      let countryCode = '';
      if (!this.state.countryCode) {
        countryCode = response.default_country_code.toLowerCase()
      }
      if (response !== null) {
        this.addTrackingEvent(response);
      }
      this.setState({
        widgetSettings: response,
        countryCode: countryCode,
        isSameDay,
        disableCalls,
        activeTab,
        scheduleTime,
        scheduleDate,
        allowWidget: allowWidget,
        modalIsOpen: modalIsOpen
      });
      this.customFieldProcessing();
    });
  }

  componentWillMount() {
    this.loadWidgetSettings();
  }

  toggleModal = () => {
    if (this.state.widgetSettings.widget_type === 0 || this.state.widgetSettings.widget_type === 2) {
      var apikey = null;
      var agent_id = null;
      if (this.state.widgetSettings.widget_type === 2) {
        apikey = this.state.widgetSettings.pId;
        agent_id = getAPIkey();
      } else {
        apikey = getAPIkey();
      }
      if (!this.state.modalIsOpen) {
        triggerPublicEvent('widgetOpened', null)
        if (this.state.widgetSettings.google_analytics === 1) {
          const params = {
            type: 'widget_opened',
            pId: apikey,
            userIp: this.state.widgetSettings.ip_address
          };
          sendAnalyticsEvent(params);
        }
      } else {
        triggerPublicEvent('widgetClosed', null)
        if (this.state.widgetSettings.google_analytics === 1) {
          const params = {
            type: 'widget_closed',
            pId: apikey,
            userIp: this.state.widgetSettings.ip_address
          };
          sendAnalyticsEvent(params);
        }
      }
      this.setState(prevState => ({
        modalIsOpen: !prevState.modalIsOpen
      }));
    }
  }

  pushButtonEventToGA = () => {
    if (this.state.widgetSettings.google_analytics === 1) {
      var apikey = null;
      var agent_id = null;
      if (this.state.widgetSettings.widget_type === 2) {
        apikey = this.state.widgetSettings.pId;
        agent_id = getAPIkey();
      } else {
        apikey = getAPIkey();
      }
      const params = {
        type: 'button_clicked',
        pId: apikey,
        userIp: this.state.widgetSettings.ip_address
      };
      sendAnalyticsEvent(params);
    }
  }

  stopPropagation = (e) => {
    e.stopPropagation();
  }
  onFeedbackRecieved = () => {
    this.setState({
      modalIsOpen: false
    });
  }

  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  arrowRenderer = () => <div />

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  match = (line, word) => {
    if (line === undefined) {
      return false;
    }
    return line.includes(word);
  }
  changeNavLInk(nav) {
    // console.log(nav)
    this.setState({
      activeTab: nav
    })
  }
  addTimeTriggerEventHandler() {
    if (!(document.once_per_session && document.triggerEventCalled === true)) {
      if (document.custom_triggers) {
        if (document.customTriggerAttacher) {
          return;
        }
      }
      document.customTriggerAttacher = true;
      document.getElementById('limecall-widget-modal').style.display = 'flex'
      document.triggerEventCalled = true;
    }
  }
  addTimeTriggerEvent(triggerTime) {
    if (!(this.state.widgetSettings.triggers.trigger_once_per_session && document.triggerEventCalled === true)) {
      setTimeout(this.addTimeTriggerEventHandler, (triggerTime * 1000));
    }
  }
  addScrollTriggerEvent(scrollTime, once_per_event) {
    document.scrollTime = scrollTime;
    document.scrollTime = scrollTime;
    document.once_per_event = once_per_event;
    window.addEventListener('scroll', function (evt) {
      // console.log(this.state);
      // return;
      let bottomScrollPosition = document.body.offsetHeight - window.screen.height;
      let currentScrollPosition = window.pageYOffset;
      let scrollPosition = parseInt((currentScrollPosition / bottomScrollPosition) * 100);
      if (scrollTime === scrollPosition) {
        // console.log("triggered scroll event");
        // console.log("condition", !(document.once_per_event && document.triggerEventCalled))
        if (!(document.once_per_event && document.triggerEventCalled)) {
          document.triggerEventCalled = true;
          document.getElementById('limecall-widget-modal').style.display = 'flex'
        }
      }

    });
  }
  addEvent(obj, evt, fn) {
    if (obj.addEventListener) {
      obj.addEventListener(evt, fn, false);
    }
    else if (obj.attachEvent) {
      obj.attachEvent("on" + evt, fn);
    }
  };

  addExitEventTrigger() {

    this.addEvent(document, "mouseout", function (event) {
      event = event ? event : window.event;
      var from = event.relatedTarget || event.toElement;
      if ((!from || from.nodeName === "HTML") && event.clientY <= 100) {
        if (!(document.once_per_event && document.triggerEventCalled)) {
          document.triggerEventCalled = true;
          document.getElementById('limecall-widget-modal').style.display = 'flex'

        }
      }
    });

  }

  addTrackingEvent(widget_settings) {
    const pageData = {
      ip_address: widget_settings.ip_address,
      visitied_on: widget_settings.pageSessionStartTime,
      page_url: widget_settings.source
    }
    let cookieName = "limecall_" + window.location.hostname;
    var browsingHistory = [];
    if (this.getCookie(cookieName) === null) {
      browsingHistory.push(pageData);
      let browsingHistoryData = JSON.stringify(browsingHistory);
      this.setCookie(cookieName, browsingHistoryData, 365)
    } else {
      browsingHistory = JSON.parse(this.getCookie(cookieName));
      browsingHistory.push(pageData);
      let browsingHistoryData = JSON.stringify(browsingHistory);
      this.setCookie(cookieName, browsingHistoryData, 365)
    }
  }

  setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }


  getCookie(name) {

    var dc = document.cookie;
    var prefix = name + "=";
    var end;
    var begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
      begin = dc.indexOf(prefix);
      if (begin !== 0) return null;
      else {
        var oneCookie = dc.indexOf(';', begin);
        if (oneCookie === -1) {
          end = dc.length;
        } else {
          end = oneCookie;
        }
        return dc.substring(begin, end).replace(prefix, '');
      }

    }
    else {
      begin += 2;
      var end = document.cookie.indexOf(";", begin);
      if (end === -1) {
        end = dc.length;
      }
      var fixed = dc.substring(begin, end).replace(prefix, '');
    }
    // return decodeURI(dc.substring(begin + prefix.length, end));
    return fixed;
  }

  deleteCookie(name) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  getRegexOperator(operator, value) {
    let url = window.location.href;
    if (operator === "url-not-regex") {
      if (!url.match(value)) {
        return true;
      }
    }
    if (operator === "url-regex") {
      if (url.match(value)) {
        return true;
      }
    }
    if (operator === 'url-not-contains') {
      if (!url.match('.*' + value + '.*')) {
        return true;

      }
    }
    if (operator === 'url-contains') {
      if (url.match('.*' + value + '.*')) {
        return true;
      }
    }
    if (operator === 'url-not-ends') {
      if (!url.match('(.*' + value + '$)|(.*' + value + '$)')) {
        return true;
      }
    }
    if (operator === 'url-ends') {
      if (url.match('(.*' + value + '$)|(.*' + value + '$)')) {
        return true;
      }
    }
    if (operator === 'url-not-starts') {
      if (!url.match('(^' + value + '.*)|(^' + value + '.*)')) {
        return true;
      }
    }
    if (operator === 'url-starts') {
      if (url.match('(^' + value + '.*)|(^' + value + '.*)')) {
        return true;
      }
    }
    if (operator === 'url-ends') {
      if (url.match('.*' + value + '$')) {
        return true;
      }
    }
    if (operator === 'url-equals') {
      if (url === value || url === ('/' + value) || url === (value + '/') || url === ('/' + value + '/')) {
        return true;
      }
    }
    if (operator === 'url-match') {
      if (url.match('.*' + value + '.*')) {
        return true;
      }
    }
    if (operator === 'url-not-match') {
      if (!url.match('.*' + value + '.*')) {
        return true;
      }
    }

    return false;
  }

  getReadableNumber = (number) => {
    let sufix = 'th';
    number += 1;
    switch (number) {
      case 1:
        sufix = 'st';
        break;
      case 2:
        sufix = 'nd';
        break;
      case 3:
        sufix = 'rd';
        break;
      default:
        sufix = 'th'
    }
    return (number + sufix);
  }

  addWidgetPopupListeners() {
    if (this.state.triggerAdded) {
      return;
    }
    this.setState({
      triggerAdded: true
    })
    // console.log("adding triggers");
    document.once_per_event = this.state.widgetSettings.triggers.trigger_once_per_session;
    if (this.state.widgetSettings.triggers.exit_intent_trigger) {
      // console.log("adding exit event trigger");
      this.addExitEventTrigger();
    }
    if (this.state.widgetSettings.triggers.time_trigger_btn) {
      // console.log("adding time event trigger");
      this.addTimeTriggerEvent(this.state.widgetSettings.triggers.time_trigger_value);
    }
    if (this.state.widgetSettings.triggers.scroll_trigger_btn) {
      // console.log("adding scroll event trigger");
      this.addScrollTriggerEvent(this.state.widgetSettings.triggers.scroll_trigger_value, this.state.widgetSettings.triggers.trigger_once_per_session);
    }
  }

  phoneNumberChangeHandler = (status, value, countryData, number) => {
    let intlCustomCLass = "";
    if (status) {
      intlCustomCLass = "";
    } else {
      intlCustomCLass = "intlErrorContainer";
    }

    this.setState({
      phoneNumber: value,
      phoneNumberIsValid: status,
      phoneNumberError: !status,
      intlCustomCLass: intlCustomCLass,
      finalNumber: number,
      countryDialCode: `+${countryData.dialCode}`,
      countryCode: countryData.iso2
    });
  }

  onSelectFlagHandler = (value, countryData, number, status) => {
    let intlCustomCLass = "";
    if (status) {
      intlCustomCLass = "";
    } else {
      intlCustomCLass = "intlErrorContainer";
    }
    this.setState({
      phoneNumber: value,
      phoneNumberIsValid: status,
      phoneNumberError: !status,
      intlCustomCLass: intlCustomCLass,
      finalNumber: number,
      countryDialCode: `+${countryData.dialCode}`,
      countryCode: countryData.iso2
    });
  }

  customFieldProcessing = () => {
    // console.log("custom field processing");
    var enable_cf_on_before_call = false, enable_cf_on_before_schedule = false, enable_cf_on_after_schedule = false, enable_cf_on_after_call = false, enable_cf_on_during_call = false, enable_cf_on_leave_message = false, enable_cf_on_before_digitalcall = false, enable_cf_on_after_digitalcall = false, enable_cf_on_during_digitalcall = false;
    let custom_fields_array = [], custom_fields_before_call = [], custom_fields_before_digitalcall = [], custom_fields_before_schedule = [], custom_fields_after_schedule = [], custom_fields_after_call = [], custom_fields_after_digitalcall = [], custom_fields_during_call = [], custom_fields_during_digitalcall = [], custom_fields_leave_message = [];
    if (!this.state.widgetSettings.custom_fields) {
      return;
    }
    for (let tmpI = 0, a = 0, b = 0, c = 0, d = 0, e = 0, f = 0, g = 0, h = 0, i = 0; tmpI < this.state.widgetSettings.custom_fields.length; tmpI++) {
      if (this.state.widgetSettings.custom_fields[tmpI].enabled === 1) {
        if (this.state.widgetSettings.custom_fields[tmpI].tab === "all_tabs") {
          custom_fields_leave_message[a] = this.state.widgetSettings.custom_fields[tmpI];
          enable_cf_on_leave_message = true;
          a++;
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_now === "on_call_now_screen") {
            custom_fields_before_call[b] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_before_call = true;
            b++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_now === "during_call") {
            custom_fields_during_call[c] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_during_call = true;
            c++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_now === "after_call") {
            custom_fields_after_call[d] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_after_call = true;
            d++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_later === "before_schedule") {
            custom_fields_before_schedule[e] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_before_schedule = true;
            e++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_later === "after_schedule") {
            custom_fields_after_schedule[f] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_after_schedule = true;
            f++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_digital_call === "before_call") {
            custom_fields_before_digitalcall[g] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_before_digitalcall = true;
            g++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_digital_call === "during_call") {
            custom_fields_during_digitalcall[h] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_during_digitalcall = true;
            h++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_digital_call === "after_call") {
            custom_fields_after_digitalcall[i] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_after_digitalcall = true;
            i++;
          }
        } else if (this.state.widgetSettings.custom_fields[tmpI].tab === "leave_message_tab") {
          custom_fields_leave_message[a] = this.state.widgetSettings.custom_fields[tmpI];
          enable_cf_on_leave_message = true;
          a++;
        } else if (this.state.widgetSettings.custom_fields[tmpI].tab === "call_now_tab") {
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_now === "on_call_now_screen") {
            custom_fields_before_call[b] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_before_call = true;
            b++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_now === "during_call") {
            custom_fields_during_call[c] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_during_call = true;
            c++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_now === "after_call") {
            custom_fields_after_call[d] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_after_call = true;
            d++;
          }
        } else if (this.state.widgetSettings.custom_fields[tmpI].tab === "digital_call") {
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_digital_call === "before_call") {
            custom_fields_before_digitalcall[g] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_before_digitalcall = true;
            g++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_digital_call === "during_call") {
            custom_fields_during_digitalcall[h] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_during_digitalcall = true;
            h++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_digital_call === "after_call") {
            custom_fields_after_digitalcall[i] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_after_digitalcall = true;
            i++;
          }
        } else if (this.state.widgetSettings.custom_fields[tmpI].tab === "call_later_tab") {
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_later === "before_schedule") {
            custom_fields_before_schedule[e] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_before_schedule = true;
            e++;
          }
          if (this.state.widgetSettings.custom_fields[tmpI].display_on_call_later === "after_schedule") {
            custom_fields_after_schedule[f] = this.state.widgetSettings.custom_fields[tmpI];
            enable_cf_on_after_schedule = true;
            f++;
          }
        }
        custom_fields_array[tmpI] = this.state.widgetSettings.custom_fields[tmpI].type;
      }
    }
    this.setState({
      custom_fields_leave_message_data: custom_fields_leave_message,
      custom_fields_before_call_data: custom_fields_before_call,
      custom_fields_during_call_data: custom_fields_during_call,
      custom_fields_after_call_data: custom_fields_after_call,
      custom_fields_before_schedule_data: custom_fields_before_schedule,
      custom_fields_after_schedule_data: custom_fields_after_schedule,
      enable_cf_on_before_call: enable_cf_on_before_call,
      enable_cf_on_before_schedule: enable_cf_on_before_schedule,
      enable_cf_on_after_schedule: enable_cf_on_after_schedule,
      enable_cf_on_after_call: enable_cf_on_after_call,
      enable_cf_on_during_call: enable_cf_on_during_call,
      enable_cf_on_leave_message: enable_cf_on_leave_message,
      enable_cf_on_after_digitalcall: enable_cf_on_after_digitalcall,
      enable_cf_on_before_digitalcall: enable_cf_on_before_digitalcall,
      enable_cf_on_during_digitalcall: enable_cf_on_during_digitalcall,
      custom_fields_after_digitalcall_data: custom_fields_after_digitalcall,
      custom_fields_during_digitalcall_data: custom_fields_during_digitalcall,
      custom_fields_before_digitalcall_data: custom_fields_before_digitalcall
    })
  }

  customVisibilityAttacher(visibilityTriggers_status, custom_visibility_triggers) {
    let triggers_dataset = [];
    if (visibilityTriggers_status === 1) {
      for (var i = 0, j = 0; i < custom_visibility_triggers.length; i++) {
        let tmpCustomTargetData = custom_visibility_triggers[i];
        let tmpUsefulData = [];
        if (tmpCustomTargetData.key === "url") {
          if (this.getRegexOperator(tmpCustomTargetData.operator, tmpCustomTargetData.value)) {
            // console.log(tmpCustomTargetData.operator+"  "+tmpCustomTargetData.value+ " is triggering it");
            tmpUsefulData['option'] = tmpCustomTargetData.option;
            if (tmpCustomTargetData.visibility_rule_type === "show_on") {
              tmpUsefulData['value'] = 1;
            }
            if (tmpCustomTargetData.visibility_rule_type === "block_on") {
              tmpUsefulData['value'] = 0;
            }
          } else {
            tmpUsefulData['option'] = tmpCustomTargetData.option;
            if (tmpCustomTargetData.visibility_rule_type === "show_on") {
              tmpUsefulData['value'] = 0;
            }
            if (tmpCustomTargetData.visibility_rule_type === "block_on") {
              tmpUsefulData['value'] = 1;
            }
          }
          triggers_dataset[j] = tmpUsefulData;
          j++;
        }
      }
    }
    var finalCond;
    if (visibilityTriggers_status === 0) {
      finalCond = 1;
    } else {
      for (var tempCond, i = 0; i < triggers_dataset.length; i++) {
        let triggerSet = triggers_dataset[i];
        if (i === 0) {
          finalCond = triggerSet.value;
        } else {
          if (triggerSet.option === "and") {
            tempCond = finalCond && triggerSet.value;
          } else if (triggerSet.option === "or") {
            tempCond = finalCond || triggerSet.value;
          }
          finalCond = tempCond;
        }
      }
    }
    if (finalCond === 1) {
      this.setState({
        widgetVisibility: 1
      })
    }
  }

  customTriggerAttacher() {
    document.custom_triggers = this.state.widgetSettings.custom_triggers;
    let triggers_dataset = [];
    if (this.state.widgetSettings.custom_triggers) {
      for (var i = 0, j = 0; i < this.state.widgetSettings.custom_triggers.length; i++) {
        let tmpCustomTargetData = this.state.widgetSettings.custom_triggers[i];
        let tmpUsefulData = [];
        if (tmpCustomTargetData.key === "url") {
          if (this.getRegexOperator(tmpCustomTargetData.operator, tmpCustomTargetData.value)) {
            // console.log(tmpCustomTargetData.operator+"  "+tmpCustomTargetData.value+ " is triggering it");
            tmpUsefulData['option'] = tmpCustomTargetData.option;
            tmpUsefulData['value'] = 1;
          } else {
            tmpUsefulData['option'] = tmpCustomTargetData.option;
            tmpUsefulData['value'] = 0;
          }
          triggers_dataset[j] = tmpUsefulData;
          j++;
        }
      }
    }
    var finalCond;
    for (var tempCond, i = 0; i < triggers_dataset.length; i++) {
      let triggerSet = triggers_dataset[i];
      if (i === 0) {
        finalCond = triggerSet.value;
      } else {
        if (triggerSet.option === "and") {
          tempCond = finalCond && triggerSet.value;
        } else if (triggerSet.option === "or") {
          tempCond = finalCond || triggerSet.value;
        }
        finalCond = tempCond;
      }
    }
    if (finalCond === 1) {
      if (document.customTriggerAttacher === undefined) {
        this.addTimeTriggerEvent(2);
        document.customTargetAttacher = true;
      }
    }
  }

  loadSocialProofText = (this_tab) => {
    var txt;
    if (this_tab === "message_form") {
      txt = "You are already the " + this.getReadableNumber(this.state.widgetSettings.number_of_messages_today) + " person who has left a message";
      return txt;
    } else if (this_tab === "digital_call" || this_tab === "normal_call") {
      txt = "You are already the " + this.getReadableNumber(this.state.widgetSettings.number_of_calls_today) + " person who has requested a call";
      return txt;
    } else if (this_tab === "schedule_call") {
      txt = "You are already the " + this.getReadableNumber(this.state.widgetSettings.number_of_scheduled_calls_today) + " person who has ordered a call";
      return txt;
    }
  }
  loadClassicTemplate = () => {
    window.limeCallTemplate = 1;
    let classicTemplateType = this.state.widgetSettings.classic_template_design_type;
    let bubblePosition = this.state.widgetSettings.bubble_position;
    let classicTemplateClass = "modal limecall-modal animated";
    let classicModalClass = 'modal-dialog limecall-modal-dialog limecall-widget lime-call-popup';
    let classicCloseClass = "limecall-desktop-close close";
    let sideBubbleClass = "limecall-widgetSideBubble";
    if (classicTemplateType === 1) {
      classicCloseClass = "limecall-desktop-close close";
      classicTemplateClass = 'modal limecall-modal animated slidetemplate ';
      classicModalClass = 'modal-dialog limecall-modal-dialog limecall-widget lime-call-popup slideModal';

      if (this.state.widgetSettings.bubble_template === 2) {
        if (bubblePosition === "top_right" || bubblePosition === "bottom_right") {
          bubblePosition = "middle_right"
          classicTemplateClass = classicTemplateClass + " middleRight fast fadeInRight"
          sideBubbleClass = sideBubbleClass + " middleRightBubble"
        }
        if (bubblePosition === "top_left" || bubblePosition === "bottom_left") {
          bubblePosition = "middle_left"
          classicTemplateClass = classicTemplateClass + " middleLeft fast fadeInLeft"
          sideBubbleClass = sideBubbleClass + " middleLeftBubble"
        }
      }

      if (this.state.widgetSettings.widget_type === 0 || this.state.widgetSettings.widget_type === 2) {
        if (bubblePosition === "top_right") {
          classicTemplateClass = classicTemplateClass + " topRight fast fadeInRight"
        } else if (bubblePosition === "top_left") {
          classicTemplateClass = classicTemplateClass + " topLeft fast fadeInLeft"
        } else if (bubblePosition === "bottom_right") {
          classicTemplateClass = classicTemplateClass + " bottomRight fast fadeInRight"
        } else if (bubblePosition === "bottom_left") {
          classicTemplateClass = classicTemplateClass + " bottomLeft fast fadeInLeft"
        }
      } else {
        classicTemplateClass = classicTemplateClass + " shareableWid"
      }
    }
    const disableMessage = this.state.disableCalls ? "The call limit for this widget has exceeded!" : "";
    return (
      <React.Fragment >
        {(this.state.widgetSettings.bubble_template === 1) ?
          [(this.state.widgetSettings.widget_type === 0 || this.state.widgetSettings.widget_type === 2) ?
            (
              <div className="limecall-widget popup-button" dangerouslySetInnerHTML={this.triggererListener()} style={this.floatingStyle[this.state.widgetSettings['bubble_position']]}>
                {(!this.state.modalIsOpen && this.match(this.state.widgetSettings['bubble_position'], "right") && this.state.widgetSettings.bubble_text_status) ? (<div className="twilio-popup" style={{ fontFamily: (this.state.widgetSettings.widget_font) }}>{(this.state.widgetSettings.bubble_text === undefined) ? "Hey there! How can we help?" : this.state.widgetSettings.bubble_text}</div>) : null}
                <div role="presentation" onClick={this.toggleModal} id="limeCallWid" className="animated infinite pulse twilio-wd-open-widget-popup" style={{ background: this.state.widgetSettings.circle_color, 'borderRadius': ((this.state.widgetSettings.shape === "default") ? "50%" : "0%") }}>
                  {(!this.state.modalIsOpen) ? (<i className="material-icons">call</i>) : (<i className="material-icons">close</i>)}
                </div>
                {(!this.state.modalIsOpen && this.match(this.state.widgetSettings['bubble_position'], "left") && this.state.widgetSettings.bubble_text_status) ? (<div className="twilio-popup">{(this.state.widgetSettings.bubble_text === undefined) ? "Hey there! How can we help?" : this.state.widgetSettings.bubble_text}</div>) : null}
              </div>
            ) : null] : null
        }
        {
          (this.state.widgetSettings.bubble_template === 2 && !this.state.modalIsOpen) ? (
            <div className={sideBubbleClass} style={{ background: this.state.widgetSettings.circle_color, fontFamily: this.state.widgetSettings.widget_font }} onClick={this.toggleModal}>
              Request a Callback
            </div>
          ) : null
        }

        <div className={classicTemplateClass} onClick={this.toggleModal} id="limecall-widget-modal" role="dialog" style={{ display: ((this.state.modalIsOpen) ? "flex " : "none"), fontFamily: this.state.widgetSettings.widget_font }} >
          <div className={classicModalClass} onClick={this.stopPropagation} role="document" style={{ opacity: '1' }}>
            <div className="modal-content limecall-modal-content" style={{ border: 'none' }}>
              {(this.state.widgetSettings.widget_type === 1) ? null : <div role="presentation" onClick={this.toggleModal} className={classicCloseClass}><i className="material-icons">close</i></div>}
              <ul className="nav nav-tabs" style={{ display: "flex" }}>
                {(this.state.widgetSettings.digital_call) ? (
                  <li
                    onClick={() => this.toggle('clicktocall')}
                    style={{ fontFamily: (this.state.widgetSettings.widget_font), borderBottom: (this.state.activeTab === 'clicktocall' ? '3px solid ' + this.state.widgetSettings.submit_request_call_color : 'none') }}
                    className={`nav-item ${this.state.activeTab === 'clicktocall' ? 'active' : ''} borderedItem`}>
                    <a id="clicktocall" className=" borderedItem nav-link limecall-nav-link" >
                      <i className="material-icons">headset_mic</i>
                      <div style={this.fontStyle}>{(this.state.widgetSettings.language_data === undefined) ? "Web call" : this.state.widgetSettings.language_data["digital_call_tab_title"]}</div>
                    </a>
                  </li>

                ) : null}
                <li
                  onClick={() => this.toggle('callnow')}
                  style={{ fontFamily: (this.state.widgetSettings.widget_font), display: (((this.state.isSameDay && this.state.widgetSettings.call_now) && !this.state.disableCalls) ? "" : "none"), borderBottom: (this.state.activeTab === 'callnow' ? '3px solid ' + this.state.widgetSettings.submit_request_call_color : 'none') }}
                  className={`nav-item ${this.state.activeTab === 'callnow' ? 'active' : ''} borderedItem`}>
                  <a id="callnow" className=" borderedItem nav-link limecall-nav-link" disabled={this.state.disableCalls}><i className="material-icons">call</i>
                    <div style={this.fontStyle}>{(this.state.widgetSettings.language_data === undefined) ? "Call me now" : this.state.widgetSettings.language_data["call-now-tab"]}</div>
                  </a>
                </li>
                {(this.state.widgetSettings.call_me_later) ? (
                  <li
                    onClick={() => this.toggle('calllater')}
                    style={{ fontFamily: (this.state.widgetSettings.widget_font), borderBottom: (this.state.activeTab === 'calllater' ? '3px solid ' + this.state.widgetSettings.submit_request_call_color : 'none') }}
                    className={`nav-item ${this.state.activeTab === 'calllater' ? 'active' : ''} borderedItem`}>
                    <a id="calllater" className=" borderedItem nav-link limecall-nav-link" >
                      <i className="material-icons">restore</i>
                      <div style={this.fontStyle}>{(this.state.widgetSettings.language_data === undefined) ? "Call me later" : this.state.widgetSettings.language_data["call-later-tab"]}</div>
                    </a>
                  </li>

                ) : null}
                {(this.state.widgetSettings.leave_message) ? (
                  <li
                    onClick={() => this.setState({ activeTab: "leavemessage" })}
                    style={{ borderBottom: (this.state.activeTab === 'leavemessage' ? '3px solid ' + this.state.widgetSettings.submit_request_call_color : 'none') }}
                    className={`nav-item ${this.state.activeTab === 'leavemessage' ? 'active' : ''} borderedItem`}>
                    <a className=" borderedItem nav-link limecall-nav-link" id="leavemessage"><i className="material-icons">message</i>
                      <div style={this.fontStyle}>
                        {(this.state.widgetSettings.language_data === undefined) ? "Message us" : this.state.widgetSettings.language_data["leave-message-tab"]}
                      </div>
                    </a></li>
                ) : null}
              </ul>
              <div className="tab-content">
                {(this.state.widgetSettings.digital_call) ? (
                  <div className={`tab-pane ${this.state.activeTab === 'clicktocall' ? 'active' : ''} `} >
                    <div className="modal-body limecall-modal-body">
                      <DigitalCallForm
                        currentTab="click_to_call_tab"
                        toggle={this.toggle.bind(this)}

                        pushButtonEventToGA={this.pushButtonEventToGA.bind(this)}
                        showLoader={this.state.showLoader}
                        widgetSettings={this.state.widgetSettings}
                        activeTab={this.state.activeTab}
                        enable_cf_on_after_digitalcall={this.state.enable_cf_on_after_digitalcall}
                        enable_cf_on_before_digitalcall={this.state.enable_cf_on_before_digitalcall}
                        enable_cf_on_during_digitalcall={this.state.enable_cf_on_during_digitalcall}
                        custom_fields_after_digitalcall_data={this.state.custom_fields_after_digitalcall_data}
                        custom_fields_during_digitalcall_data={this.state.custom_fields_during_digitalcall_data}
                        custom_fields_before_digitalcall_data={this.state.custom_fields_before_digitalcall_data}
                        style={{ fontFamily: this.state.widgetSettings.widget_font }}
                        title={(this.state.widgetSettings.digital_call_tab_text) ? this.state.widgetSettings.digital_call_tab_text : this.state.widgetSettings.language_data["digital_call_heading"]}
                      />
                    </div>
                    {this.renderTermsAndConditionsLink("digital_call")}
                  </div>
                ) : null}
                <div className={`tab-pane ${this.state.activeTab === 'callnow' ? 'active' : ''} `}>
                  <div className="modal-body limecall-modal-body">
                    <WidgetCallForm
                      currentTab="call_now_tab"
                      toggle={this.toggle.bind(this)}
                      pushButtonEventToGA={this.pushButtonEventToGA.bind(this)}
                      showLoader={this.state.showLoader}
                      phoneNumberChangeHandler={this.phoneNumberChangeHandler.bind(this)}
                      onSelectFlagHandler={this.onSelectFlagHandler.bind(this)}
                      countryCode={this.state.countryCode}
                      countryDialCode={this.state.countryDialCode}
                      phoneNumber={this.state.phoneNumber}
                      finalNumber={this.state.finalNumber}
                      phoneNumberError={this.state.phoneNumberError}
                      intlCustomCLass={this.state.intlCustomCLass}
                      phoneNumberIsValid={this.state.phoneNumberIsValid}
                      style={{ fontFamily: this.state.widgetSettings.widget_font }}
                      widgetSettings={this.state.widgetSettings}
                      scheduleTime={this.state.scheduleTime}
                      custom_fields_before_call_data={this.state.custom_fields_before_call_data}
                      custom_fields_during_call_data={this.state.custom_fields_during_call_data}
                      custom_fields_after_call_data={this.state.custom_fields_after_call_data}
                      enable_cf_on_before_call={this.state.enable_cf_on_before_call}
                      enable_cf_on_after_call={this.state.enable_cf_on_after_call}
                      enable_cf_on_during_call={this.state.enable_cf_on_during_call}
                      scheduleDate={this.state.scheduleDate}
                      activeTab={this.state.activeTab}
                      title={(this.state.widgetSettings.widget_text) ? this.state.widgetSettings.widget_text : "Do you want us to call you in 28 seconds ?"}
                    />
                  </div>
                  {this.renderTermsAndConditionsLink("normal_call")}
                </div>
                {(this.state.widgetSettings.call_me_later) ? (
                  <div className={`tab-pane ${this.state.activeTab === 'calllater' ? 'active' : ''} `} >
                    <div className="modal-body limecall-modal-body">
                      <WidgetCallForm
                        scheduleCall
                        currentTab="call_later_tab"
                        toggle={this.toggle.bind(this)}
                        pushButtonEventToGA={this.pushButtonEventToGA.bind(this)}
                        showLoader={this.state.showLoader}
                        phoneNumberChangeHandler={this.phoneNumberChangeHandler.bind(this)}
                        countryCode={this.state.countryCode}
                        countryDialCode={this.state.countryDialCode}
                        phoneNumber={this.state.phoneNumber}
                        finalNumber={this.state.finalNumber}
                        phoneNumberError={this.state.phoneNumberError}
                        intlCustomCLass={this.state.intlCustomCLass}
                        phoneNumberIsValid={this.state.phoneNumberIsValid}
                        widgetSettings={this.state.widgetSettings}
                        scheduleTime={this.state.scheduleTime}
                        enable_cf_on_before_schedule={this.state.enable_cf_on_before_schedule}
                        enable_cf_on_after_schedule={this.state.enable_cf_on_after_schedule}
                        custom_fields_before_schedule_data={this.state.custom_fields_before_schedule_data}
                        custom_fields_after_schedule_data={this.state.custom_fields_after_schedule_data}
                        scheduleDate={this.state.scheduleDate}
                        activeTab={this.state.activeTab}
                        style={{ fontFamily: this.state.widgetSettings.widget_font }}
                        title={(this.state.widgetSettings.call_now === 0) ? this.state.widgetSettings.offline_schedule_call_message : (this.state.widgetSettings.widget_text) ? this.state.widgetSettings.schedule_call_tab_text : "Would you like us to call you later? Choose best time for callback!"}
                      />
                    </div>
                    {this.renderTermsAndConditionsLink("schedule_call")}
                  </div>
                ) : null}
                {(this.state.widgetSettings.leave_message) ? (
                  <div className={`tab-pane ${this.state.activeTab === 'leavemessage' ? 'active' : ''} `}>
                    <div className="modal-body limecall-modal-body">
                      <MessageForm
                        currentTab='leave_message_tab'
                        toggle={this.toggle.bind(this)}
                        pushButtonEventToGA={this.pushButtonEventToGA.bind(this)}
                        showLoader={this.state.showLoader}
                        widgetSettings={this.state.widgetSettings}
                        activeTab={this.state.activeTab}
                        enable_cf_on_leave_message={this.state.enable_cf_on_leave_message}
                        custom_fields_leave_message_data={this.state.custom_fields_leave_message_data}
                        style={{ fontFamily: this.state.widgetSettings.widget_font }}
                        title={`${this.state.widgetSettings.leave_message_tab_text}`}
                      />
                    </div>
                    {this.renderTermsAndConditionsLink("message_form")}
                  </div>
                ) : null}
                <div className={`tab-pane ${this.state.activeTab === 'terms-page' ? 'active' : ''}`} >
                  <h3 className="limecall-title" style={{ margin: "4% auto", fontFamily: (this.state.widgetSettings.widget_font) }} >
                    Terms and Conditions
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: this.state.widgetSettings.configuring_terms }} id="limecall-terms-lines" style={{ fontFamily: (this.state.widgetSettings.widget_font), width: "80%", margin: "auto" }}>

                  </p>
                </div>
              </div>
              <div onClick={this.toggleModal} className="limecall-close-btn-mobile"><span><p>Close</p></span></div>
              <div className="poweredby-container" id="poweredbyContainer">
                <p style={{ marginBottom: '0', textAlign: "center", lineHeight: "11px" }}>
                  <a href={`http://limecall.com/pages/?utm_source=${window.location.origin}`} target="_blank" rel="noopener noreferrer" className="poweredby-section"><img src="https://limecall.com/wp-content/uploads/2020/01/favicon.png" alt="" className="poweredby-icon" />We run on LimeCall</a>
                </p>
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    )
  }
  renderTermsAndConditionsLink = (this_tab) => (
    <React.Fragment>
      <div>
        {(this.state.widgetSettings.social_proof === 1) ?
          <div className="terms-apply" style={{ position: 'relative', fontFamily: this.state.widgetSettings.widget_font, textDecoration: 'double', fontSize: '10.5px' }}>{this.loadSocialProofText(this_tab)}</div>
          : null
        }

        {(this.state.widgetSettings.terms_and_conditions_status === 1) ?
          <div className="terms-apply">
            {(this.state.widgetSettings.configuring_terms_type === 'url') ?
              <a href={this.state.widgetSettings.configuring_terms} style={{ fontFamily: this.state.widgetSettings.widget_font }} target="_blank" rel="noopener noreferrer">Terms</a>

              :
              <a href="#" style={{ fontFamily: this.state.widgetSettings.widget_font }} onClick={(e) => { this.toggle('terms-page') }}>Terms</a>
            }
          </div>
          : null
        }

        {(this.state.widgetSettings.privacy_note_status === 1) ?
          <div className="terms-apply" style={{ position: 'relative', fontFamily: this.state.widgetSettings.widget_font, textDecoration: 'double', fontSize: '9px' }}><i className="material-icons" style={{ fontSize: 'inherit' }}>verified_user</i> {this.state.widgetSettings.privacy_note} </div>
          : null
        }

      </div>

    </React.Fragment>
  )

  triggererListener() {
    this.customTriggerAttacher();
    if (this.state.widgetSettings.triggers) {
      var device = 'desktop';
      if (navigator.userAgent.indexOf('Android') !== -1 || navigator.userAgent.indexOf('iPad') !== -1 || navigator.userAgent.indexOf('iPhone') !== -1) {
        device = 'mobile';
      }
      if (this.state.widgetSettings.mobile_and_desktop_targeting === 'both' || this.state.widgetSettings.mobile_and_desktop_targeting === device) {
        this.addWidgetPopupListeners();
      }
    }
  }

  loadSmartTemplate = () => {
    window.limeCallTemplate = 2;
    return (
      <React.Fragment>
        <SmartTemplate
          widgetSettings={this.state.widgetSettings}
          scheduleTime={this.state.scheduleTime}
          scheduleDate={this.state.scheduleDate}
          pushButtonEventToGA={this.pushButtonEventToGA.bind(this)}
          enable_cf_on_after_digitalcall={this.state.enable_cf_on_after_digitalcall}
          enable_cf_on_before_digitalcall={this.state.enable_cf_on_before_digitalcall}
          enable_cf_on_during_digitalcall={this.state.enable_cf_on_during_digitalcall}
          custom_fields_after_digitalcall_data={this.state.custom_fields_after_digitalcall_data}
          custom_fields_during_digitalcall_data={this.state.custom_fields_during_digitalcall_data}
          custom_fields_before_digitalcall_data={this.state.custom_fields_before_digitalcall_data}
          enable_cf_on_before_schedule={this.state.enable_cf_on_before_schedule}
          enable_cf_on_after_schedule={this.state.enable_cf_on_after_schedule}
          custom_fields_before_schedule_data={this.state.custom_fields_before_schedule_data}
          custom_fields_after_schedule_data={this.state.custom_fields_after_schedule_data}
        />
      </React.Fragment>
    )
  }

  loadModernTemplate = () => {
    window.limeCallTemplate = 3;
    return (
      <React.Fragment>
        <ModernTemplate
          widgetSettings={this.state.widgetSettings}
          scheduleTime={this.state.scheduleTime}
          scheduleDate={this.state.scheduleDate}
          pushButtonEventToGA={this.pushButtonEventToGA.bind(this)}
          phoneNumberChangeHandler={this.phoneNumberChangeHandler.bind(this)}
          onSelectFlagHandler={this.onSelectFlagHandler.bind(this)}
          countryCode={this.state.countryCode}
          countryDialCode={this.state.countryDialCode}
          phoneNumber={this.state.phoneNumber}
          finalNumber={this.state.finalNumber}
          phoneNumberError={this.state.phoneNumberError}
          intlCustomCLass={this.state.intlCustomCLass}
          phoneNumberError={this.state.phoneNumberError}
          phoneNumberIsValid={this.state.phoneNumberIsValid}
          enable_cf_on_after_digitalcall={this.state.enable_cf_on_after_digitalcall}
          enable_cf_on_before_digitalcall={this.state.enable_cf_on_before_digitalcall}
          enable_cf_on_during_digitalcall={this.state.enable_cf_on_during_digitalcall}
          custom_fields_after_digitalcall_data={this.state.custom_fields_after_digitalcall_data}
          custom_fields_during_digitalcall_data={this.state.custom_fields_during_digitalcall_data}
          custom_fields_before_digitalcall_data={this.state.custom_fields_before_digitalcall_data}
          enable_cf_on_before_schedule={this.state.enable_cf_on_before_schedule}
          enable_cf_on_after_schedule={this.state.enable_cf_on_after_schedule}
          custom_fields_before_schedule_data={this.state.custom_fields_before_schedule_data}
          custom_fields_after_schedule_data={this.state.custom_fields_after_schedule_data}
        />
      </React.Fragment>
    )
  }

  render() {
    // console.log('active tab is :'+this.state.activeTab);
    return (
      <React.Fragment >
        {(this.state.widgetVisibility === 1) ?
          <React.Fragment >
            {(this.state.widgetSettings.template_type === 1) ?
              this.loadClassicTemplate() : null
            }
            {(this.state.widgetSettings.template_type === 2) ?
              this.loadSmartTemplate() : null
            }
            {(this.state.widgetSettings.template_type === 3) ?
              this.loadModernTemplate() : null
            }
          </React.Fragment>
          : null}
      </React.Fragment>
    );
  }
}

var limeCallClient = new LimeCallClientClass()
// var limeWidget =  new Widget()
limeCallClient = limeCallClient.renderLibrary()
ReactDOM.render(<LimeCallClientClass
  ref={() => { window.limeCallClient = limeCallClient }}
/>, document.getElementById("limecall-widget"));

export default Widget;
